const { intl } = window.inlineGlobalConfig.hashedPaths;

if (!global.Intl) {
  const xhttp = new XMLHttpRequest();
  xhttp.open('GET', intl, false);
  xhttp.send(null);

  const headTag = document.getElementsByTagName('head')[0];
  const scriptTag = document.createElement('script');
  scriptTag.type = 'text/javascript';
  scriptTag.text = xhttp.responseText;
  headTag.appendChild(scriptTag);
}
