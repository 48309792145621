/* eslint-disable */
/*
  This file is generated with 'modernizr-webpack-plugin'
  Read more details in webpack.config.js file
*/
!(function(e, n, t) {
  function o(e, n) {
    return typeof e === n;
  }
  function r() {
    return 'function' != typeof n.createElement
      ? n.createElement(arguments[0])
      : S
      ? n.createElementNS.call(n, 'http://www.w3.org/2000/svg', arguments[0])
      : n.createElement.apply(n, arguments);
  }
  function s() {
    var e = n.body;
    return e || ((e = r(S ? 'svg' : 'body')), (e.fake = !0)), e;
  }
  function i(e, t, o, i) {
    var l,
      a,
      f,
      u,
      c = 'modernizr',
      p = r('div'),
      d = s();
    if (parseInt(o, 10)) for (; o--; ) (f = r('div')), (f.id = i ? i[o] : c + (o + 1)), p.appendChild(f);
    return (
      (l = r('style')),
      (l.type = 'text/css'),
      (l.id = 's' + c),
      (d.fake ? d : p).appendChild(l),
      d.appendChild(p),
      l.styleSheet ? (l.styleSheet.cssText = e) : l.appendChild(n.createTextNode(e)),
      (p.id = c),
      d.fake &&
        ((d.style.background = ''),
        (d.style.overflow = 'hidden'),
        (u = w.style.overflow),
        (w.style.overflow = 'hidden'),
        w.appendChild(d)),
      (a = t(p, e)),
      d.fake ? (d.parentNode.removeChild(d), (w.style.overflow = u), w.offsetHeight) : p.parentNode.removeChild(p),
      !!a
    );
  }
  function l(e, n) {
    return !!~('' + e).indexOf(n);
  }
  function a(e) {
    return e
      .replace(/([A-Z])/g, function(e, n) {
        return '-' + n.toLowerCase();
      })
      .replace(/^ms-/, '-ms-');
  }
  function f(n, t, o) {
    var r;
    if ('getComputedStyle' in e) {
      r = getComputedStyle.call(e, n, t);
      var s = e.console;
      if (null !== r) o && (r = r.getPropertyValue(o));
      else if (s) {
        var i = s.error ? 'error' : 'log';
        s[i].call(s, 'getComputedStyle returning null, its possible modernizr test results are inaccurate');
      }
    } else r = !t && n.currentStyle && n.currentStyle[o];
    return r;
  }
  function u(n, o) {
    var r = n.length;
    if ('CSS' in e && 'supports' in e.CSS) {
      for (; r--; ) if (e.CSS.supports(a(n[r]), o)) return !0;
      return !1;
    }
    if ('CSSSupportsRule' in e) {
      for (var s = []; r--; ) s.push('(' + a(n[r]) + ':' + o + ')');
      return (
        (s = s.join(' or ')),
        i('@supports (' + s + ') { #modernizr { position: absolute; } }', function(e) {
          return 'absolute' == f(e, null, 'position');
        })
      );
    }
    return t;
  }
  function c(e) {
    return e
      .replace(/([a-z])-([a-z])/g, function(e, n, t) {
        return n + t.toUpperCase();
      })
      .replace(/^-/, '');
  }
  function p(e, n, s, i) {
    function a() {
      p && (delete j.style, delete j.modElem);
    }
    if (((i = !o(i, 'undefined') && i), !o(s, 'undefined'))) {
      var f = u(e, s);
      if (!o(f, 'undefined')) return f;
    }
    for (var p, d, m, h, v, y = ['modernizr', 'tspan', 'samp']; !j.style && y.length; )
      (p = !0), (j.modElem = r(y.shift())), (j.style = j.modElem.style);
    for (m = e.length, d = 0; m > d; d++)
      if (((h = e[d]), (v = j.style[h]), l(h, '-') && (h = c(h)), j.style[h] !== t)) {
        if (i || o(s, 'undefined')) return a(), 'pfx' != n || h;
        try {
          j.style[h] = s;
        } catch (e) {}
        if (j.style[h] != v) return a(), 'pfx' != n || h;
      }
    return a(), !1;
  }
  function d(e, n) {
    return function() {
      return e.apply(n, arguments);
    };
  }
  function m(e, n, t) {
    var r;
    for (var s in e) if (e[s] in n) return !1 === t ? e[s] : ((r = n[e[s]]), o(r, 'function') ? d(r, t || n) : r);
    return !1;
  }
  function h(e, n, t, r, s) {
    var i = e.charAt(0).toUpperCase() + e.slice(1),
      l = (e + ' ' + P.join(i + ' ') + i).split(' ');
    return o(n, 'string') || o(n, 'undefined')
      ? p(l, n, r, s)
      : ((l = (e + ' ' + E.join(i + ' ') + i).split(' ')), m(l, n, t));
  }
  function v(e, n, o) {
    return h(e, t, t, n, o);
  }
  var y = [],
    g = {
      _version: '3.6.0',
      _config: {
        classPrefix: '',
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0,
      },
      _q: [],
      on: function(e, n) {
        var t = this;
        setTimeout(function() {
          n(t[e]);
        }, 0);
      },
      addTest: function(e, n, t) {
        y.push({ name: e, fn: n, options: t });
      },
      addAsyncTest: function(e) {
        y.push({ name: null, fn: e });
      },
    },
    C = function() {};
  (C.prototype = g), (C = new C());
  var x = [],
    w = n.documentElement,
    S = 'svg' === w.nodeName.toLowerCase(),
    b = g._config.usePrefixes ? ' -webkit- -moz- -o- -ms- '.split(' ') : ['', ''];
  g._prefixes = b;
  var _ = (g.testStyles = i);
  C.addTest('touchevents', function() {
    var t;
    if ('ontouchstart' in e || (e.DocumentTouch && n instanceof DocumentTouch)) t = !0;
    else {
      var o = ['@media (', b.join('touch-enabled),('), 'heartz', ')', '{#modernizr{top:9px;position:absolute}}'].join(
        ''
      );
      _(o, function(e) {
        t = 9 === e.offsetTop;
      });
    }
    return t;
  });
  var z = 'Moz O ms Webkit',
    P = g._config.usePrefixes ? z.split(' ') : [];
  g._cssomPrefixes = P;
  var T = { elem: r('modernizr') };
  C._q.push(function() {
    delete T.elem;
  });
  var j = { style: T.elem.style };
  C._q.unshift(function() {
    delete j.style;
  });
  var E = g._config.usePrefixes ? z.toLowerCase().split(' ') : [];
  (g._domPrefixes = E),
    (g.testAllProps = h),
    (g.testAllProps = v),
    C.addTest('flexbox', v('flexBasis', '1px', !0)),
    (function() {
      var e, n, t, r, s, i, l;
      for (var a in y)
        if (y.hasOwnProperty(a)) {
          if (
            ((e = []),
            (n = y[a]),
            n.name && (e.push(n.name.toLowerCase()), n.options && n.options.aliases && n.options.aliases.length))
          )
            for (t = 0; t < n.options.aliases.length; t++) e.push(n.options.aliases[t].toLowerCase());
          for (r = o(n.fn, 'function') ? n.fn() : n.fn, s = 0; s < e.length; s++)
            (i = e[s]),
              (l = i.split('.')),
              1 === l.length
                ? (C[l[0]] = r)
                : (!C[l[0]] || C[l[0]] instanceof Boolean || (C[l[0]] = new Boolean(C[l[0]])), (C[l[0]][l[1]] = r)),
              x.push((r ? '' : 'no-') + l.join('-'));
        }
    })(),
    (function(e) {
      var n = w.className,
        t = C._config.classPrefix || '';
      if ((S && (n = n.baseVal), C._config.enableJSClass)) {
        var o = new RegExp('(^|\\s)' + t + 'no-js(\\s|$)');
        n = n.replace(o, '$1' + t + 'js$2');
      }
      C._config.enableClasses && ((n += ' ' + t + e.join(' ' + t)), S ? (w.className.baseVal = n) : (w.className = n));
    })(x),
    delete g.addTest,
    delete g.addAsyncTest;
  for (var N = 0; N < C._q.length; N++) C._q[N]();
  e.Modernizr = C;
})(window, document);
