import { saveStorageData } from '../localStorage/localStorage';
import { SESSION_STORAGE } from '../localStorage/localStorageConstants';
import { isIOS } from '../browserHelpers';
import { PAGE_REFERRER_STORAGE_KEY } from './referrerTrackerConstants';

const referrerEventType = isIOS() ? 'pagehide' : 'beforeunload';

window.addEventListener(referrerEventType, () => {
  const { pathname, search, hash } = window.location;
  saveStorageData(PAGE_REFERRER_STORAGE_KEY, { url: pathname + search + hash }, SESSION_STORAGE);
});
